import React, { forwardRef, useImperativeHandle, useEffect, useState, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { userAtom } from '../../../core/config/atoms';

import { ScrollArea } from "@/components/ui/scroll-area";
import { Send, Video, Mic, MicOff, VideoOff, Phone } from 'lucide-react';

import { Device } from 'mediasoup-client';
import moment from 'moment';
import { toast } from 'react-toastify';

import socket from '../../../core/config/socket';

import PaneReview from './PaneReview';
import CustomeTimer from 'core/widgets/components/CustomeTimer';

const PaneMeet = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        joinMeet,
    }));

    const [userData] = useRecoilState(userAtom);

    const [mDevice, setMDevice] = useState(null);

    const [prdTransport, setProducerTransport] = useState(null);
    const [conTransport, setConsumerTransport] = useState(null);

    const [prdAudio, setPrdAudio] = useState(null);
    const [prdVideo, setPrdVideo] = useState(null);

    const [consumerStreams, setConsumerStreams] = useState({});

    const [chats, setChats] = useState([]);

    const [roomid, setRoomID] = useState('');

    const [startTime, setStartTime] = useState(new Date());

    const [, setMClock] = useState(0);

    const [isStarted, setIsStarted] = useState(false);
    const [isVid, setIsVid] = useState(true);
    const [isAudio, setIsAudio] = useState(true);
    const [isJoin, setIsJoin] = useState(false);
    const [isReview, setIsReview] = useState(false);

    const localStreamRef = useRef();
    const chatRef = useRef('');

    const joinMeet = (roomid) => {
        if (isStarted) {
            toast.warn('Please end the current meet before joinning.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        } else {
            setRoomID(roomid);
            socket.emit('joinMeet', { roomid: roomid, iid: userData._id, name: `${userData.user.fname} ${userData.user.lname}`, type: 'interpreter' }, (rtpCapabilities) => {
                initMediaSoup(rtpCapabilities);
            });
        }
    };

    const sendChat = () => {
        const message = chatRef.current.value;
        if (message !== "") {
            let chat = {
                name: `${userData.user.fname} ${userData.user.lname}`,
                message: message,
                time: moment(Date.now()).toISOString(),
            }
            socket.emit('sendMessage', { roomid: roomid, chat });
            chatRef.current.value = "";
        }
    }

    const initMediaSoup = async (rtpCapabilities) => {
        try {
            let dev = new Device();
            await dev.load({
                routerRtpCapabilities: rtpCapabilities
            });
            setMDevice(dev);
        } catch (error) {
            console.log(error);
            if (error.name === 'UnsupportedError') {
                console.warn('browser not supported');
            }
        }
    }

    const connectToMeet = () => {
        setIsStarted(true);
        initTransports(roomid);
    }

    const connectSendTransport = () => {
        connectAudio();
        connectVideo();
    }

    const connectAudio = async () => {
        const track = localStreamRef.current.srcObject.getAudioTracks()[0];
        const params = { track };
        let producer = await prdTransport.produce(params);

        producer.on('trackended', () => {
            const producer_id = prdAudio.id;
            socket.emit('producerClosed', {
                producer_id
            });
            prdAudio.close();
            setPrdAudio(null);
        });

        producer.on('transportclose', () => {
            console.log('Producer transport close')
            setPrdAudio(null);
        });

        producer.on('close', () => {
            console.log('Closing producer')
            setPrdAudio(null);
        });

        setPrdAudio(producer);
    }

    const connectVideo = async () => {
        const track = localStreamRef.current.srcObject.getVideoTracks()[0];
        const params = { track };
        let producer = await prdTransport.produce(params);

        producer.on('trackended', () => {
            const producer_id = prdVideo.id;
            socket.emit('producerClosed', {
                producer_id
            });
            prdVideo.close();
            setPrdVideo(null);
        });

        producer.on('transportclose', () => {
            console.log('Producer transport close')
            setPrdVideo(null);
        });

        producer.on('close', () => {
            console.log('Closing producer')
            setPrdVideo(null);
        });

        setPrdVideo(producer);
    }

    const initTransports = async (rmid) => {
        // init producerTransport
        {
            socket.emit('createWebRtcTransport', { roomid: rmid }, (paramsA) => {
                if (paramsA.error) {
                    console.error(paramsA.error)
                    return
                }

                let producerTransport = mDevice.createSendTransport(paramsA);

                producerTransport.on('connect', async function ({ dtlsParameters }, callback, errback) {
                    socket.emit('connectTransport', { roomid: rmid, transport_id: paramsA.id, dtlsParameters }, (res) => {
                        console.log(res);
                    });
                    callback();
                }.bind(this));

                producerTransport.on('produce', async function ({ kind, rtpParameters }, callback, errback) {
                    await socket.emit('produce', {
                        roomid: rmid,
                        producerTransportId: producerTransport.id,
                        kind,
                        rtpParameters
                    }, (res) => {
                        callback({
                            id: res.producer_id
                        });
                    });
                }.bind(this));

                producerTransport.on('connectionstatechange', function (state) {
                    console.log(state);
                    switch (state) {
                        case 'failed':
                            producerTransport.close();
                            break;
                        default:
                            break;
                    }
                }.bind(this));

                producerTransport.on('failed', function (error) {
                    console.error('Producer transport failed:', error);
                }.bind(this));

                setProducerTransport(producerTransport);

                // init consumerTransport
                {
                    socket.emit('createWebRtcTransport', { roomid: rmid }, (paramsB) => {
                        if (paramsB.error) {
                            console.error(paramsB.error)
                            return
                        }

                        let consumerTransport = mDevice.createRecvTransport(paramsB);
                        consumerTransport.on('connect', function ({ dtlsParameters }, callback, errback) {
                            socket.emit('connectTransport', { roomid: rmid, transport_id: consumerTransport.id, dtlsParameters }, (res) => {
                                console.log(res);
                            });
                            callback();
                        }.bind(this));

                        consumerTransport.on('connectionstatechange', async function (state) {
                            console.log(state);
                            switch (state) {
                                case 'failed':
                                    consumerTransport.close();
                                    break;
                                default:
                                    break;
                            }
                        }.bind(this));

                        consumerTransport.on('failed', function (error) {
                            console.error('Consumer transport failed:', error);
                        }.bind(this));

                        setConsumerTransport(consumerTransport);

                        socket.emit('getProducers', { roomid: rmid });
                    });
                }
            });
        }
    }

    const initLocalVideo = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        localStreamRef.current.srcObject = stream;
    }

    const toggleVideo = () => {
        if (prdVideo) {
            if (isVid) {
                prdVideo.pause();
            } else {
                prdVideo.resume();
            }
            setIsVid(!isVid);
        }
    }

    const toggleAudio = () => {
        if (prdAudio) {
            if (isAudio) {
                prdAudio.pause();
            } else {
                prdAudio.resume();
            }
            setIsAudio(!isAudio);
        }
    }

    const getConsumeStream = async (producerId, sid) => {
        const { rtpCapabilities } = mDevice;
        socket.emit('consume-agent', { roomid, rtpCapabilities, consumerTransportId: conTransport.id, producerId }, async (param) => {
            const { id, kind, rtpParameters } = param;
            let codecOptions = {}
            const consumer = await conTransport.consume({
                id,
                producerId,
                kind,
                rtpParameters,
                codecOptions
            });

            if (consumerStreams[sid] !== null) {
                const newStream = new MediaStream();
                newStream.addTrack(consumer.track);
                consumerStreams[sid] = newStream;
            } else {
                consumerStreams[sid].addTrack(consumer.track);
            }

            setConsumerStreams(consumerStreams);
            setMClock(prev => prev + 1);
        });
    }

    const endCall = () => {
        socket.emit('endRoom', { roomid }, () => {
            console.log('Room Ended');
        });
    }

    const onReview = () => {
        setIsReview(false);
        setRoomID('');
        toast.warn("Call ended.", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
    }

    const getLocalCss = () => {
        if (!isJoin) {
            return 'col-span-12';
        } else {
            if (Object.keys(consumerStreams).length > 1) {
                return 'col-span-12 xl:col-span-3 flex xl:block';
            } else {
                return 'absolute w-[22%] right-[20px] bottom-[20px] z-10';
            }
        }
    }

    useEffect(() => {
        if (conTransport !== null && mDevice !== null) {
            socket.on('newProducers', async function (data) {
                let tempData = [];
                if (data.length !== 0) {
                    if (Array.isArray(data)) {
                        data.map((item) => {
                            tempData.push(item);
                        });
                    } else {
                        tempData.push(data);
                    }
                    for (let { producer_id, producer_socket_id } of tempData) {
                        await getConsumeStream(producer_id, producer_socket_id);
                    }
                }
            }.bind(this));
        }
    }, [conTransport, mDevice]);

    useEffect(() => {
        if (roomid !== '') {
            socket.on('endedRooms', ({ romid }) => {
                if (roomid === romid) {
                    setIsStarted(false);
                    setIsJoin(false);
                    setChats([]);
                    setMDevice(null);
                    setProducerTransport(null);
                    setConsumerTransport(null);
                    setConsumerStreams({});
                    setIsReview(true);
                }
            });
        }
    }, [roomid]);

    useEffect(() => {
        if (prdTransport !== null) {
            connectSendTransport();
        }
    }, [prdTransport]);

    useEffect(() => {
        if (mDevice !== null && roomid !== '') {
            connectToMeet();
        }
    }, [mDevice, roomid]);

    useEffect(() => {
        initLocalVideo();
        socket.on('ticketStatus', (stsData) => {
            if (stsData.status === "live") {
                setStartTime(moment(stsData.calltime).toDate());
                setIsJoin(true);
            }
        });
        socket.on('recvChat', (chat) => {
            let chatList = [...chats];
            chatList.push(chat);
            setChats(chatList);
        });
    }, [chats]);

    return (
        <>
            {isReview && <PaneReview id={roomid} name={`${userData.user.fname} ${userData.user.lname}`} status={isReview} onSubmit={onReview} />}
            <div className='col-span-8 h-full rounded-lg flex flex-col items-center justify-center'>
                <div className='w-full h-full flex xl:items-center xl:justify-center'>
                    <div className='w-full'>
                        <div className='grid grid-cols-12 gap-4 relative'>
                            {
                                isJoin && Object.keys(consumerStreams).length === 1 && <div className="col-span-12 pt-[160%] xl:pt-[56.25%]">
                                    <video ref={(element) => {
                                        if (element) {
                                            element.srcObject = consumerStreams[Object.keys(consumerStreams)[0]];
                                        }
                                    }} autoPlay playsInline muted className='border-2 border-prime rounded-2xl object-cover xl:object-fill absolute inset-0 w-full h-full' />
                                </div>
                            }
                            {
                                isJoin && Object.keys(consumerStreams).length > 1 && <div className="col-span-9 hidden xl:block">
                                    <div className='w-full pt-[100%] xl:pt-[56.25%] relative'>
                                        <video ref={(element) => {
                                            if (element) {
                                                element.srcObject = consumerStreams[Object.keys(consumerStreams)[0]];
                                            }
                                        }} autoPlay playsInline muted className='border-2 border-prime rounded-2xl object-cover xl:object-fill absolute inset-0 w-full h-full' />
                                    </div>
                                </div>
                            }
                            <div className={`${getLocalCss()}`}>
                                <div className={`w-full bg-black border-2 border-prime rounded-2xl overflow-hidden ${isJoin ? Object.keys(consumerStreams).length === 1 ? "pt-[160%] xl:pt-[56.25%]" : "mx-1 xl:mx-0 my-2 xl:my-0 pt-[30%] xl:pt-[56.25%]" : "pt-[56.25%]"} relative`}>
                                    <video ref={localStreamRef} autoPlay playsInline muted className='object-cover xl:object-fill absolute left-[50%] translate-x-[-50%] inset-0 h-full' />
                                </div>
                                {
                                    isJoin && Object.keys(consumerStreams).length > 1 && Object.keys(consumerStreams).map((key, index) => {
                                        if (index === 0) return <div></div>;
                                        return <div className='w-full bg-black border-2 border-prime rounded-2xl overflow-hidden pt-[30%] mx-1 xl:mx-0 xl:pt-[56.25%] relative my-2'>
                                            <video ref={(element) => {
                                                if (element) {
                                                    element.srcObject = consumerStreams[key];
                                                }
                                            }} autoPlay playsInline muted className='object-cover xl:object-fill absolute left-[50%] translate-x-[-50%] inset-0 h-full' />
                                        </div>;
                                    })
                                }
                            </div>
                        </div>
                        <div className='w-full h-[60px] mt-4'>
                            <div className={`flex items-center ${Object.keys(consumerStreams).length > 1 ? "justify-start" : "justify-center"}`}>
                                <div className={`w-10 h-10 cursor-pointer rounded-2xl mx-2 text-white flex items-center justify-center ${isVid ? 'bg-gray-800' : 'bg-red-600'}`} onClick={() => {
                                    toggleVideo();
                                }}>
                                    {isVid && <Video size={18} />}
                                    {!isVid && <VideoOff size={18} />}
                                </div>
                                <div className={`w-10 h-10 cursor-pointer rounded-2xl mx-2 text-white flex items-center justify-center ${isAudio ? 'bg-gray-800' : 'bg-red-600'}`} onClick={() => {
                                    toggleAudio();
                                }}>
                                    {isAudio && <Mic size={18} />}
                                    {!isAudio && <MicOff size={18} />}
                                </div>
                                <div className='w-10 h-10 cursor-pointer rounded-2xl mx-2 text-white flex items-center justify-center bg-red-600' onClick={() => {
                                    endCall();
                                }}>
                                    <Phone size={18} />
                                </div>
                                {Object.keys(consumerStreams).length > 0 && <CustomeTimer isJoin={isJoin} stime={startTime} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-span-4 flex items-center justify-center'>
                <div className='bg-gray-50 w-full h-full max-h-[80vh] rounded-xl flex flex-col p-6 border'>
                    <div className='p-2'>
                        <span className='text-lg font-medium'>Chats</span>
                    </div>
                    <div className='h-[1px] bg-gray-900' ></div>
                    <ScrollArea className="w-full min-h-[40vh] flex-grow bg-gray-100 my-2 p-2 rounded-md">
                        {
                            chats.map((chat) => {
                                return <div className='w-full mb-4'>
                                    <div className='w-full mb-2 flex'>
                                        <div className='flex-grow'>
                                            <h1 className='text-sm font-medium'>{chat.name}</h1>
                                            <p className='text-xs text-gray-600 mt-1'>{chat.message}</p>
                                        </div>
                                        <div>
                                            <p className='text-xs text-gray-400'>{moment(chat.time).format('hh:mm')}</p>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            })
                        }
                    </ScrollArea>
                    <div className="flex">
                        <input type="text" placeholder="Your Message" disabled={!isStarted} className="w-full h-11 rounded-md bg-gray-200 px-4 py-2 text-sm font-sans" required autoComplete="off" ref={chatRef} />
                        <button className='w-12 h-11 bg-prime ml-2 rounded-md text-white flex items-center justify-center' onClick={() => {
                            sendChat();
                        }}>
                            <Send size={18} />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
});

export default PaneMeet;
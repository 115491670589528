import React, { useState, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { userAtom, attandanceAtom } from '../../core/config/atoms';

import { motion } from "framer-motion";
import { toast } from 'react-toastify';

import TopBar from '../../core/widgets/ui/TopBar';

import PaneAttandance from './panes/PaneAttandance';
import PaneMeet from './panes/PaneMeet';

import CustomeTicketsList from 'core/widgets/components/CustomeTicketsList';

export default function PanelMain(props) {

    const [userData] = useRecoilState(userAtom);
    const [attandance] = useRecoilState(attandanceAtom);

    const [isOpen, setIsOpen] = useState(false);

    const paneMeetRef = useRef();

    const Open = () => {
        setIsOpen(true);
    }

    const joinMeet = (id, status) => {
        if (status === "open" || status === "iwait") {
            paneMeetRef.current.joinMeet(id);
        } else {
            toast.warn("Ticked already been assinged to an agent.", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        }
    }

    return (
        <div className='w-full h-screen overflow-hidden flex'>
            {isOpen && <CustomeTicketsList join={joinMeet} />}
            <div className="w-full h-full py-3 px-3">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10">
                            <h2 class="text-2xl font-medium truncate ml-2 text-gray-900">
                                <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { setIsOpen(!isOpen) }}></motion.i> {userData.user.fname}{userData.user.lname === "NA" ? "" : ` ${userData.user.lname}`}
                            </h2>
                            <TopBar />
                        </div>
                        <hr className='mt-2' />
                    </div>
                    <div className="col-span-12 mt-2 h-screen">
                        <div className='w-full h-[86%] px-2 flex items-center justify-center'>
                            <div className='h-full w-full grid grid-cols-12 gap-4 my-2 md:my-4'>
                                {attandance.status !== 'open' && <PaneAttandance Open={Open} />}
                                {attandance.status === 'open' && <PaneMeet ref={paneMeetRef} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
